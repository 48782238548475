.homepage {
  text-align: center;

  .introduction {
    margin-top: 5%;
    font-family: 'Lexend Exa', sans-serif;
    font-size: 2vw;

    .my-name {
      color:  #357ae8;
      font-size: 3vw;
    }
  }

  .hitter-phrase {
    margin-top:2%;
    font-family: 'Lexend Exa', sans-serif;
    font-size: 4vw;
    margin-bottom: 20px;
  }

  .languages-container {
    align-content: center;

    .language-image {
      width: 6%;
      height: 6%;
      margin: 2%;
    }    
  }

  .frameworks-container {
    align-content: center;


    .framework-image{
      vertical-align: middle;
      margin: 0 4%;
      width: 15%;
      height: 10%;
    }
  }



}