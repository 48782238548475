body {
  font-family: 'Open Sans Condensed';
  padding: 20px 60px;
  background-color: #DEE4E7;

  /* font-family: 'Lexend Exa', sans-serif;
  font-family: 'Source Sans Pro', sans-serif; */
}

a {
  text-decoration: none;
  color: black;
}

* {
  box-sizing: border-box;
}
